import { useCallback, useMemo, FC, ReactNode } from "react";
import { PromotionEventType, useSegment } from "src/hooks/useSegment";
import {
  Brand,
  BrandInjectedProps,
  SEGMENT_IN_VIEW_THRESHOLD,
  SEGMENT_PROMOTION_TYPE,
} from "src/segment";
import {
  HomePageSingleBrand,
  ImageBannerData,
  SummaryBrand,
} from "src/types/graphql.d";
import { useInView } from "react-intersection-observer";
import { getFileName } from "src/utils/getFileName";
import { formatName } from "src/utils/formatName";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";

interface SegmentBrandTrackingProps {
  brand: Brand;
  children: (props: BrandInjectedProps) => ReactNode;
  cover?: boolean;
  favourite?: boolean;
  position: string;
  tab?: string;
  promotionType?: SEGMENT_PROMOTION_TYPE;
}

const SegmentBrandTracking: FC<SegmentBrandTrackingProps> = ({
  brand,
  children,
  cover = false,
  favourite = false,
  position,
  tab,
  promotionType,
}) => {
  const { __typename: typeName } = brand;

  const isImageBanner = typeName === "ImageBannerData";
  const isLoggedIn = useIsLoggedIn();
  const { enableGatedHomepage } = useFeatureFlags();

  const { image, subtitle, discount, name, cover_image } = brand as
    | SummaryBrand
    | HomePageSingleBrand;

  const {
    link,
    title: imageBannerTitle,
    subtitle: imageBannerSubtitle,
  } = brand as ImageBannerData;

  const trackingName = useMemo(() => {
    const promoName: [string, string] = ["", ""];
    if (favourite) {
      promoName[0] = formatName(name);
      promoName[1] = subtitle || "";
    } else if (isImageBanner) {
      promoName[0] = formatName(imageBannerTitle);
      promoName[1] = imageBannerSubtitle || "";
    } else {
      promoName[0] = formatName(name);
      promoName[1] = discount || "";
    }

    return promoName.filter((v) => !!v).join(" - ");
  }, [
    favourite,
    subtitle,
    name,
    isImageBanner,
    discount,
    imageBannerTitle,
    imageBannerSubtitle,
  ]);

  const trackingData = useMemo(
    () => ({
      creative: isImageBanner ? link : getFileName(cover ? cover_image : image),
      name: trackingName,
      position,
      tab,
      promotionType,
      gated: !!(!isLoggedIn && enableGatedHomepage),
    }),
    [
      isImageBanner,
      link,
      cover,
      cover_image,
      image,
      trackingName,
      position,
      tab,
      promotionType,
      isLoggedIn,
      enableGatedHomepage,
    ]
  );

  const [ref] = useInView({
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        void segmentPromotionClickedOrViewed({
          ...trackingData,
          eventType: PromotionEventType.view,
        });
      }
    },
  });

  const { segmentPromotionClickedOrViewed } = useSegment();

  const onClickHandler = useCallback(
    () =>
      void segmentPromotionClickedOrViewed({
        ...trackingData,
        eventType: PromotionEventType.click,
      }),
    [trackingData, segmentPromotionClickedOrViewed]
  );

  return <div ref={ref}>{children({ onClickHandler })}</div>;
};

export default SegmentBrandTracking;
