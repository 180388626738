import { FC, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { Button } from "src/atoms/Button";
import { getGenderId, setGender } from "src/modules/page";
import { useSegment } from "src/hooks/useSegment";
import { Box } from "@otrium/core";

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.tone.black};
  flex-flow: column;
  padding: 304px 0 304px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.tone.sand};
  flex: 1 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 144px 0;
  }
`;

const Styledh1 = styled.h1`
  font-size: 32px;
  letter-spacing: 2.4px;
  line-height: 40px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 24px;
    letter-spacing: 1.6px;
    line-height: 32px;
  }
`;

const StyledP = styled.p`
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-top: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 14px;
    margin: 8px 16px 0 16px;
  }
`;

interface Props {
  href?: string;
  text?: JSX.Element;
  title?: JSX.Element | string;
}

const Error: FC<Props> = ({ href = "/", text, title }) => {
  const dispatch = useDispatch();
  const genderId = useSelector(getGenderId);
  const { segmentTrackingError } = useSegment();

  useEffect(() => {
    if (!genderId) {
      dispatch(setGender("women"));
    }
    void segmentTrackingError({
      error_type: "status",
      error_message: "Page not found",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genderId, dispatch]);

  return (
    <StyledContainer>
      <Styledh1>{title ? title : <Trans>{"We're sorry"}</Trans>}</Styledh1>
      <StyledP data-testid="error-page-description">
        {text ? (
          text
        ) : (
          <>
            <Trans>It looks like something went wrong our side.</Trans>
            <br />
            <Trans>
              We’re trying to fix this right now so please try back later. In
              the meantime you can continue shopping.
            </Trans>
          </>
        )}
      </StyledP>
      <Box mt={[3, 3, 3, 4]}>
        <Button
          as="a"
          variant="primary"
          data-testid="error-page-url"
          href={href}
        >
          <Trans>Continue shopping</Trans>
        </Button>
      </Box>
    </StyledContainer>
  );
};

export default Error;
